<script setup>
const emit = defineEmits(['login-toggle', 'register-toggle']);

function loginToggle() {
    emit('login-toggle');
}

function registerToggle() {
    emit('register-toggle');
}
</script>

<template>
    <div class="flex items-center">
        <div class="flex gap-2 md:gap-3">
            <button @click="registerToggle" class="rounded-md">
                {{ $t('Register') }}
            </button>
            <button @click="loginToggle"
                    class="text-sm text-white ui-button-blue flex items-center gap-2 rounded text-[13px] sm:text-[15px]">
                <svg height="15" viewBox="0 0 512 512" width="15"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M344.7 273.5l-144.1 136c-6.975 6.578-17.2 8.375-26 4.594C165.8 410.3 160.1 401.6 160.1 392V320H32.02C14.33 320 0 305.7 0 288V224c0-17.67 14.33-32 32.02-32h128.1V120c0-9.578 5.707-18.25 14.51-22.05c8.803-3.781 19.03-1.984 26 4.594l144.1 136C354.3 247.6 354.3 264.4 344.7 273.5z"
                        fill="currentColor"></path>
                    <path
                        d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z"
                        fill="currentColor" opacity="0.4"></path>
                </svg>
                {{ $t('Log in') }}
            </button>
        </div>
    </div>
</template>
