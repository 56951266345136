<script setup>
import { ref, onMounted } from 'vue';
import { Tabs } from 'flowbite';

const tabs = ref(null);

const initTabs = () => {
    const tabsElement = document.getElementById('tabs-info');
    if (tabsElement) {
        const tabElements = [
            {
                id: 'default',
                triggerEl: document.querySelector('#default-tab'),
                targetEl: document.querySelector('#default-panel'),
            },
            {
                id: 'descriptions',
                triggerEl: document.querySelector('#description-tab'),
                targetEl: document.querySelector('#description-panel'),
            },
            {
                id: 'reviews',
                triggerEl: document.querySelector('#reviews-tab'),
                targetEl: document.querySelector('#reviews-panel'),
            },
        ];

        const options = {
            defaultTabId: 'default',
            activeClasses: 'text-green-600 hover:text-green-600 dark:text-green-500 dark:hover:text-green-400 border-green-600 dark:border-green-500',
            inactiveClasses: 'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
        };

        tabs.value = new Tabs(tabsElement, tabElements, options);
    }
};

onMounted(() => {
    initTabs();
});
</script>

<template>
    <div class="mb-4 border-b border-gray-200 dark:border-gray-700 mt-3 lg:mt-16">
        <ul class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400" id="tabs-info" role="tablist">
            <li class="me-2" role="presentation">
                <button class="inline-block rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300" id="default-tab" type="button" role="tab" aria-controls="default-panel" aria-selected="false">
                    {{ game.game_name }}
                </button>
            </li>
            <li class="me-2" role="presentation">
                <button class="inline-block rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300" id="description-tab" type="button" role="tab" aria-controls="description-panel" aria-selected="false">
                    {{ $t('Description') }}
                </button>
            </li>
            <li class="me-2" role="presentation">
                <button class="inline-block rounded-t-lg border-b-2 border-transparent p-4 hover:border-gray-300 hover:text-gray-600 dark:hover:text-gray-300" id="reviews-tab" type="button" role="tab" aria-controls="reviews-panel" aria-selected="false">
                    {{ $t('Reviews') }}
                </button>
            </li>
        </ul>
    </div>
    <div id="tabContentExample">
        <div class="hidden rounded-lg bg-gray-50 p-4 dark:bg-gray-800" id="default-panel" role="tabpanel" aria-labelledby="default-tab">
            <slot name="default"></slot>
        </div>
        <div class="hidden rounded-lg bg-gray-50 p-4 dark:bg-gray-800" id="description-panel" role="tabpanel" aria-labelledby="description-tab">
            <slot name="description"></slot>
        </div>
        <div class="hidden rounded-lg bg-gray-50 p-4 dark:bg-gray-800" id="reviews-panel" role="tabpanel" aria-labelledby="reviews-tab">
            <slot name="reviews"></slot>
        </div>
    </div>
</template>
