<script setup>
const loginWithGoogle = async () => {

};

const loginWithApple = async () => {
};

const loginWithFacebook = async () => {
};

const loginWithTwitch = async () => {
};

const loginWithVKontakte = async () => {
};
</script>

<template>
    <div class="flex flex-col space-y-3 mb-6">

        <div class="flex space-x-3">
            <button
                @click="loginWithGoogle"
                class="flex items-center justify-center w-1/2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-200 bg-transparent hover:bg-[rgb(55,55,55)]"
            >
                <i class="fab fa-google mr-2"></i> Google
            </button>

            <button
                @click="loginWithFacebook"
                class="flex items-center justify-center w-1/2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-200 bg-transparent hover:bg-[rgb(55,55,55)]"
            >
                <i class="fab fa-facebook mr-2"></i> Facebook
            </button>
        </div>

        <div class="flex space-x-3">
            <button
                @click="loginWithTwitch"
                class="flex items-center justify-center w-1/2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-200 bg-transparent hover:bg-[rgb(55,55,55)]"
            >
                <i class="fab fa-twitch mr-2"></i> Twitch
            </button>

            <button
                @click="loginWithVKontakte"
                class="flex items-center justify-center w-1/2 px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-200 bg-transparent hover:bg-[rgb(55,55,55)]"
            >
                <i class="fab fa-vk mr-2"></i> VKontakte
            </button>
        </div>
    </div>
</template>

