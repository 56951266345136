<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '@/Stores/Auth.js';
import HttpApi from '@/Services/HttpApi.js';

const isLoading = ref(false);
const typeInputPassword = ref('password');
const loginForm = ref({
    email: '',
    password: '',
});

const router = useRouter();
const authStore = useAuthStore();

const togglePassword = () => {
    typeInputPassword.value = typeInputPassword.value === 'password' ? 'text' : 'password';
};

const loginSubmit = async () => {
    isLoading.value = true;
    const _toast = useToast();

    try {
        const response = await HttpApi.post('auth/login', loginForm.value);
        authStore.setToken(response.data.access_token);
        authStore.setUser(response.data.user);
        authStore.setIsAuth(true);
        router.push({ name: 'home' });
        _toast.success('You have been authenticated, welcome!');
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            _toast.error(`${value}`);
        });
    } finally {
        isLoading.value = false;
    }
};
</script>

<template>
    <form @submit.prevent="loginSubmit" method="post" action="" class="">
        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-envelope text-success-emphasis"></i>
            </div>
            <input required type="text" v-model="loginForm.email" name="email" class="input-group" :placeholder="$t('Enter email or phone')">
        </div>

        <div class="relative mb-6">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-lock text-success-emphasis"></i>
            </div>
            <input required :type="typeInputPassword"
                   v-model="loginForm.password"
                   name="password"
                   class="input-group pr-[40px]"
                   :placeholder="$t('Type the password')">
            <button type="button" @click.prevent="togglePassword" class="absolute inset-y-0 right-0 flex items-center pr-3.5 ">
                <i v-if="typeInputPassword === 'password'" class="fa-regular fa-eye"></i>
                <i v-if="typeInputPassword === 'text'" class="fa-sharp fa-regular fa-eye-slash"></i>
            </button>
        </div>
        <a @click.prevent="$router.push('/forgot-password')" href="" class="text-white text-sm">{{ $t('Forgot password') }}</a>

        <div class="mt-3 w-full">
            <button type="submit" class="ui-button-blue rounded w-full mb-3">
                {{ $t('Log in') }}
            </button>
        </div>
        <p class="text-sm text-gray-300 mb-6">
            {{ $t('Not have an account yet') }}?
            <RouterLink :to="{ name: 'register' }" active-class="top-register-active" class="">
                <strong>{{ $t('Create an account') }}</strong>
            </RouterLink>
        </p>
    </form>
</template>
