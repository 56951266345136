<script setup>
import {ref, onMounted} from 'vue';
import {useAuthStore} from '@/Stores/Auth.js';
import HttpApi from '@/Services/HttpApi.js';
import {useToast} from 'vue-toastification';
import LoadingSpinner from "@/Components/UI/LoadingSpinner.vue";

const isLoadingProfile = ref(false);
const profileUser = ref(null);
const avatarUrl = ref('/assets/images/profile.jpg');
const profileName = ref('');
const readonly = ref(false);
const totalEarnings = ref(0);
const totalBets = ref(0);
const sumBets = ref(0);

const authStore = useAuthStore();
const _toast = useToast();

const getProfile = async () => {
    isLoadingProfile.value = true;
    try {
        const response = await HttpApi.get('/profile/');
        sumBets.value = response.data.sumBets;
        totalBets.value = response.data.totalBets;
        totalEarnings.value = response.data.totalEarnings;
        const user = response.data.user;
        if (user?.avatar != null) {
            avatarUrl.value = '/storage/' + user.avatar;
        }
        profileName.value = user.name;
        profileUser.value = user;
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            _toast.error(`${value}`);
        });
    } finally {
        isLoadingProfile.value = false;
    }
};

const updateName = async () => {
    isLoadingProfile.value = true;
    try {
        await HttpApi.post('/profile/updateName', {name: profileName.value});
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            _toast.error(`${value}`);
        });
    } finally {
        isLoadingProfile.value = false;
    }
};

const like = async (id) => {
    try {
        await HttpApi.post('/profile/like/' + id, {});
        getProfile();
        _toast.success('Liked successfully');
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            _toast.error(`${value}`);
        });
    }
};

onMounted(() => {
    if (authStore.isAuth) {
        getProfile();
    }
});
</script>

<template>
    <div id="modalProfileEl" tabindex="-1" aria-hidden="true"
         class="fixed top-0 left-0 right-0 z-[101] hidden w-full overflow-x-hidden overflow-y-auto md:inset-0 h-screen md:h-[calc(100%-1rem)] max-h-full">
        <div class="relative w-full max-w-2xl md:max-w-lg max-h-full bg-white dark:bg-gray-900 rounded-lg shadow-lg">
            <div v-if="!isLoadingProfile" class="flex flex-col">
                <div class="flex justify-between w-full p-4">
                    <h1 class="text-2xl font-bold">User data</h1>
                    <button @click.prevent="$emit('profile-toggle')" type="button" class="text-2xl">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div v-if="profileUser" class="flex flex-col w-full p-4">
                    <div class="flex items-center self-center justify-between w-full">
                        <button @click.prevent="like(profileUser.id)" type="button" class="heart">
                            <i class="fa-solid fa-heart"></i>
                            <span class="ml-2">{{ profileUser.totalLikes }}</span>
                        </button>
                        <div class="text-center flex flex-col justify-center self-center items-center">
                            <div class="relative">
                                <img class="w-24 h-24 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                                     :src="avatarUrl" alt="">
                                <input ref="fileInput" type="file" style="display: none" @change="handleFileChange">
                                <button @click="openFileInput" type="button" class="absolute bottom-0 right-0 text-3xl">
                                    <i class="fa-duotone fa-image"></i>
                                </button>
                            </div>
                            <div class="relative">
                                <input @change.prevent="updateName" v-model="profileName" type="text"
                                       :readonly="!readonly"
                                       class="mt-4 appearance-none border border-gray-300 rounded-md p-2 bg-transparent border-none text-center"
                                       :placeholder="profileName">
                            </div>
                        </div>
                        <div>
                            <button @click.prevent="readonly = !readonly" type="button"
                                    class="bg-gray-200 hover:bg-gray-400 dark:bg-gray-600 hover:dark:bg-gray-700 w-10 h-10 rounded">
                                <i v-if="!readonly" class="fa-sharp fa-light fa-pen"></i>
                                <i v-if="readonly" class="fa-solid fa-xmark"></i>
                            </button>
                        </div>
                    </div>
                    <div class="mt-3 shadow flex flex-col bg-gray-100 dark:bg-gray-900 rounded-lg">
                        <div class="flex justify-between px-4 pt-4">
                            <h1><span class="mr-2"><i class="fa-solid fa-chart-mixed"></i></span> Statistics</h1>
                        </div>
                        <div class="p-4">
                            <div class="grid grid-cols-3 gap-4">
                                <div class="bg-gray-200 dark:bg-gray-700 text-center p-4">
                                    <p class="text-[12px]">Total winnings</p>
                                    <p class="text-2xl font-bold">{{ totalEarnings }}</p>
                                </div>
                                <div class="bg-gray-200 dark:bg-gray-700 text-center p-4">
                                    <p class="text-[12px]">Total bets</p>
                                    <p class="text-2xl font-bold">{{ totalBets }}</p>
                                </div>
                                <div class="bg-gray-200 dark:bg-gray-700 text-center p-4">
                                    <p class="text-[12px]">Total bet</p>
                                    <p class="text-2xl font-bold">{{ sumBets }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="py-3 text-center">
                        <p>ingressou em {{ profileUser.dateHumanReadable }}</p>
                    </div>
                </div>
            </div>
            <div v-if="isLoadingProfile" class="flex flex-col w-full h-full">
                <LoadingSpinner/>
            </div>
        </div>
    </div>
</template>
