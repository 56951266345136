<script setup>
import {ref, onMounted, computed} from 'vue';
import {sidebarStore} from "@/Stores/SideBarStore.js";
import {useBottomNavigationLinkStore} from "@/Stores/BottomNavigationLinkStore.js";

const bottomNavigationLinkStore = useBottomNavigationLinkStore();
const casinoText = ref("Cassino");
const betsText = ref("Apostas");
const bottomNavigationLinks = computed(() => bottomNavigationLinkStore.bottomNavigationLinks);

const sidebarMenuStore = sidebarStore();

const toggleMenu = () => {
    sidebarMenuStore.setSidebarToogle();
};

onMounted(async () => {
    let isCassino = true;
    let isBets = true;


    setInterval(() => {
        casinoText.value = isCassino ? "Live" : "Cassino";
        isCassino = !isCassino;

        betsText.value = isBets ? "Live" : "Apostas";
        isBets = !isBets;
    }, 2000);

    await bottomNavigationLinkStore.fetchBottomNavigationLinks();
});


</script>

<template>
    <div class="fixed bottom-0 z-[100] h-16 w-full bg-[var(--navtop-color-dark)] sm:hidden">
        <div class="grid h-full grid-cols-5 w-full justify-around">
            <button @click="toggleMenu" type="button"
                    class="flex flex-col items-center justify-center text-[11px] font-semibold text-gray-500 dark:text-[#FDFAFA]">
                <span class="sr-only">{{ sidebarMenuStore.getSidebarStatus ? 'Fechar' : 'Abrir' }} menu</span>
                <svg v-if="sidebarMenuStore.getSidebarStatus" height="17" viewBox="0 0 320 512" width="17"
                     xmlns="http://www.w3.org/2000/svg" class="dark:fill-[#C0C3C3]">
                    <path
                        d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"/>
                </svg>
                <svg v-else height="17" viewBox="0 0 448 512" width="17" xmlns="http://www.w3.org/2000/svg"
                     class="dark:fill-[#C0C3C3]">
                    <path
                        d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/>
                </svg>
                Menu
            </button>

            <template v-for="link in bottomNavigationLinks">
                <a :href="link.url"
                   class="relative flex flex-col items-center justify-center text-[13px] font-semibold text-gray-500 dark:text-[#FDFAFA]">
                    <img :src="`/storage` + link.icon" :alt="link.text" height="19" width="19"
                         class="fixed-color"/>

                    <span>{{ link.text }}</span>

                    <div v-if="link.is_live_event === 1 || link.is_live_event === true" class="absolute top-2 right-[1rem] w-1 h-1 bg-red-500 rounded-full animate-blink"></div>
                </a>
            </template>
        </div>
    </div>
</template>

<style scoped>
.pulse {
    animation: pulse 2s infinite;
}

.fixed-color {
    filter: brightness(0) saturate(100%) invert(83%) sepia(2%) saturate(14%) hue-rotate(160deg) brightness(94%) contrast(84%);

}

.animate-blink {
    animation: blink 1s infinite;
}

/* Animação de piscar */
@keyframes blink {
    0%, 50%, 100% {
        opacity: 1;
    }
    25%, 75% {
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
