<script setup>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useAuthStore } from '@/Stores/Auth.js';
import HttpApi from '@/Services/HttpApi.js';

const isLoading = ref(false);
const typeInputPassword = ref('password');
const registerForm = ref({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    reference_code: '',
    term_a: false,
    agreement: false,
    spin_data: null
});

const togglePassword = () => {
    typeInputPassword.value = typeInputPassword.value === 'password' ? 'text' : 'password';
};

const registerSubmit = async () => {
    isLoading.value = true;
    const _toast = useToast();
    const authStore = useAuthStore();

    try {
        const response = await HttpApi.post('auth/register', registerForm.value);
        if (response.data.access_token !== undefined) {
            authStore.setToken(response.data.access_token);
            authStore.setUser(response.data.user);
            authStore.setIsAuth(true);

            registerForm.value = {
                name: '',
                email: '',
                password: '',
                password_confirmation: '',
                reference_code: '',
                term_a: false,
                agreement: false,
                spin_data: null
            };

            router.push({ name: 'profileDeposit' });
            _toast.success('Sua conta foi criada com sucesso');
        }
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            _toast.error(`${value}`);
        });
    } finally {
        isLoading.value = false;
    }
};
</script>

<template>
    <form @submit.prevent="registerSubmit" method="post" action="" class="">
        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-user text-success-emphasis"></i>
            </div>
            <input type="text"
                   name="name"
                   v-model="registerForm.name"
                   class="input-group"
                   :placeholder="$t('Enter name')"
                   required
            >
        </div>

        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-envelope text-success-emphasis"></i>
            </div>
            <input type="email"
                   name="email"
                   v-model="registerForm.email"
                   class="input-group"
                   :placeholder="$t('Enter email or phone')"
                   required
            >
        </div>

        <div class="relative mb-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i class="fa-regular fa-lock text-success-emphasis"></i>
            </div>
            <input :type="typeInputPassword"
                   name="password"
                   v-model="registerForm.password"
                   class="input-group pr-[40px]"
                   :placeholder="$t('Type the password')"
                   required
            >
            <button type="button" @click.prevent="togglePassword" class="absolute inset-y-0 right-0 flex items-center pr-3.5 ">
                <i v-if="typeInputPassword === 'password'" class="fa-regular fa-eye"></i>
                <i v-if="typeInputPassword === 'text'" class="fa-sharp fa-regular fa-eye-slash"></i>
            </button>
        </div>

        <div class="mt-5 w-full">
            <button type="submit" class="ui-button-blue rounded w-full mb-3">
                {{ $t('Register') }}
            </button>
        </div>
    </form>
</template>
