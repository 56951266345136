<script setup>
import { ref, onMounted } from 'vue';
import { useFirebase } from '@/composables/useFirebase';
import { useApiSports } from '@/composables/useApiSports';

const { database } = useFirebase();
const { getLeagues, getFixturesLive, getOdds, getFixtures } = useApiSports(database);

const apiData = ref(null);
const loading = ref(true);

onMounted(() => {
    getOdds().then(data => {
        apiData.value = data;
        loading.value = false;
    });
});
</script>

<template>
    <div>
        {{ apiData }}
    </div>
</template>
