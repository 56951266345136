import {defineStore} from "pinia";
import {ref} from "vue";


export const useFooterStore = defineStore("footer", {
    state() {
        return {
            footerText: ref(`Is operated by Rocket Gaming, company registration number 150731, with registered address at Groot Kwartierweg 10, Curaçao and is licensed and authorized by the Government of Curacao and operates under the Master License of Gaming Services Provider, N.V. #365/JAZ License Number: GLH-OCCHKTW0709172018. Payment agent company is Horangi Trading Limited with address Chytron, 30, 2nd floor, Flat/Office A22, 1075, Nicosia, Cyprus and Registration number: HE 411494. Gambling can be addictive. Please play responsibly. Only accepts customers over 18 years of age.`),

            columns: ref([
                {
                    id: 1,
                    icon: "fas fa-info-circle",
                    title: "SOBRE NÓS",
                    links: [
                        {id: 1, label: "Documentação Legal", url: "#"},
                        {id: 2, label: "Perguntas Frequentes", url: "#"},
                        {id: 3, label: "Sobre Nós", url: "#"},
                        {id: 4, label: "Trabalhe Conosco", url: "#"}
                    ]
                },
                {
                    id: 2,
                    icon: "fas fa-wallet",
                    title: "TRANSAÇÕES",
                    links: [
                        {id: 1, label: "Métodos de Pagamentos", url: "#"},
                        {id: 2, label: "Limites de Pagamentos", url: "#"},
                        {id: 3, label: "Realizar Depósito", url: "#"},
                        {id: 4, label: "Solicitar Retirada", url: "#"}
                    ]
                },
                {
                    id: 3,
                    icon: "fas fa-lock",
                    title: "SEGURANÇA",
                    links: [
                        {id: 1, label: "Política de Bônus", url: "#"},
                        {id: 2, label: "Política de Privacidade", url: "#"},
                        {id: 3, label: "Termos de Afiliação", url: "#"},
                        {id: 4, label: "Termos de Uso", url: "#"}
                    ]
                },
                {
                    id: 4,
                    icon: "fas fa-question-circle",
                    title: "SUPORTE",
                    links: [
                        {id: 1, label: "Formulário de Contato", url: "#"},
                        {id: 2, label: "Jogo Responsável", url: "#"},
                        {id: 3, label: "Provedor de Jogos", url: "#"},
                        {id: 4, label: "Regras da Plataforma", url: "#"}
                    ]
                },
                {
                    id: 5,
                    icon: "fas fa-credit-card",
                    title: "PAGAMENTOS",
                    links: [
                        // {id: 1, label: "Pix", url: "#", iconSvg: "/icons/pix.svg", className: "icon-pix"},
                        {id: 2, label: "Cartão", url: "#", iconSvg: "/icons/credit.svg", className: "icon-card"},
                        {id: 3, label: "Cripto", url: "#", iconSvg: "/icons/bitcoin.png", className: "icon-crypto"}
                    ]
                }
            ]),

            languages: ref([
                { code: 'es', name: 'Español', flag: '/icons/es.svg' },
                { code: 'en', name: 'English', flag: '/icons/us.svg' },
                { code: 'pt', name: 'Português', flag: '/icons/br.svg' },
                { code: 'fr', name: 'Français', flag: '/icons/fr.svg' },
                { code: 'it', name: 'Italy', flag: '/icons/it.svg' },
                { code: 'mx', name: 'México (ES)', flag: '/icons/mx.svg' },
                { code: 'in', name: 'Hindi', flag: '/icons/in.svg' },
                { code: 'de', name: 'German', flag: '/icons/de.svg'}
            ]),

            selectedLanguage: ref('es'),

            logoTM: ref({
                id: 1,
                src: "/icons/world.svg",
                alt: "Icone Global",
                width: "50px"
            })
        }
    }
});
