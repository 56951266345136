import { defineStore } from 'pinia'
import HttpApi from "@/Services/HttpApi.js";

export const useBottomNavigationLinkStore = defineStore('bottomNavigationLink', {
    state() {
        return {
            bottomNavigationLinks: [{
                id: 0,
                text: '',
                url: '',
                icon: '',
                text_color: '',
                is_live_event: false,
            }],
        }
    },

    actions: {
        async fetchBottomNavigationLinks() {
            try {
                const response = await HttpApi.get('/layout/bottom-navigation-links');
                this.bottomNavigationLinks = response.data;
            } catch (error) {}
        }
    },

    getters: {},
});
