<script setup>
import {computed, ref} from 'vue';
import {useToast} from 'vue-toastification';
import HttpApi from '@/Services/HttpApi.js';
import {useAuthStore} from '@/Stores/Auth.js';
import LoadingSpinner from '@/Components/UI/LoadingSpinner.vue';
import {useBannerStore} from "@/Stores/BannerStore.js";
import SocialAuthButtons from "@/Components/Auth/SocialAuthButtons.vue";

const emit = defineEmits(['register-toggle', 'login-toggle']);

const isLoadingLogin = ref(false);
const showPassword = ref(false);
const showForgotPasswordModal = ref(false);
const bannerStore = useBannerStore();
const banner = computed(() => bannerStore.loginBanner);

const loginForm = ref({
    email: '',
    password: '',
});

const forgotPasswordForm = ref({
    email: '',
});

const authStore = useAuthStore();
const _toast = useToast();

const loginSubmit = async () => {
    isLoadingLogin.value = true;
    try {
        const response = await HttpApi.post('auth/login', loginForm.value);
        authStore.setToken(response.data.access_token);
        authStore.setUser(response.data.user);
        authStore.setIsAuth(true);
        loginForm.value = {email: '', password: ''};
        emit('login-toggle');
        _toast.success('¡Has iniciado sesión exitosamente!');
    } catch (error) {
        if (error.request && error.request.responseText) {
            const errorData = JSON.parse(error.request.responseText);
            if (errorData) {
                Object.entries(errorData).forEach(([key, value]) => {
                    _toast.error(`${value}`);
                });
            } else {
                _toast.error('Ocurrió un error desconocido.');
            }
        } else {
            _toast.error('Error de red. Por favor, verifica tu conexión.');
        }
    } finally {
        isLoadingLogin.value = false;
    }
};

const togglePasswordVisibility = () => {
    showPassword.value = !showPassword.value;
};

const openForgotPasswordModal = () => {
    showForgotPasswordModal.value = true;
};

const closeForgotPasswordModal = () => {
    showForgotPasswordModal.value = false;
};

const sendPasswordRecovery = async () => {
    try {
        await HttpApi.post('auth/forgot-password', forgotPasswordForm.value);
        _toast.success('¡Correo de recuperación enviado!');
        forgotPasswordForm.value.email = '';
        closeForgotPasswordModal();
    } catch (error) {
        _toast.error('Ocurrió un error al enviar el correo de recuperación.');
    }
};

const registerToggle = () => {
    emit('login-toggle');
    emit('register-toggle');
};

const handleClickOutside = (event) => {
    const modalContent = document.getElementById('loginModalContent');
    if (!modalContent.contains(event.target)) {
        emit('login-toggle');
    }
};

const handleClickOutsideForgotPassword = (event) => {
    const modalContent = document.getElementById('forgotPasswordModalContent');
    if (!modalContent.contains(event.target)) {
        closeForgotPasswordModal();
    }
};

</script>

<template>
    <div
        id="modalElAuth"
        tabindex="-1"
        aria-hidden="true"
        class="fixed inset-0 z-[101] flex hidden items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        v-show="!showForgotPasswordModal"
        @click="handleClickOutside"
    >
        <div
            id="loginModalContent"
            class="relative w-full max-w-lg bg-base rounded-lg shadow-lg mx-auto md:mt-20"
            @click.stop
        >
            <div class="relative">
                <img
                    :src="`/storage` + banner.image"
                    alt="Banner"
                    class="w-full h-40 object-cover rounded-t-lg"
                />
                <button
                    @click="$emit('login-toggle')"
                    class="absolute top-2 right-2 text-white text-2xl"
                >
                    <i class="fa-solid fa-xmark"></i>
                </button>
            </div>
            <div class="p-5 max-h-[calc(100vh-4rem)] overflow-y-auto">
                <form @submit.prevent="loginSubmit" method="post" action="">
                    <div class="mb-6 text-center">
                        <h5 class="mb-3 font-bold text-xl">Iniciar sesión</h5>
                    </div>
                    <div class="relative mb-3">
                        <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none"
                        >
                            <i class="fa-regular fa-envelope text-success-emphasis"></i>
                        </div>
                        <input
                            required
                            type="email"
                            v-model="loginForm.email"
                            name="email"
                            class="input-group"
                            placeholder="Ingrese su correo electrónico"
                        />
                    </div>
                    <div class="relative mb-3">
                        <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none"
                        >
                            <i class="fa-regular fa-lock text-success-emphasis"></i>
                        </div>
                        <input
                            required
                            :type="showPassword ? 'text' : 'password'"
                            v-model="loginForm.password"
                            name="password"
                            class="input-group pr-[40px]"
                            placeholder="Ingrese su contraseña"
                        />
                        <div class="absolute inset-y-0 right-0 flex items-center pr-3.5">
                            <button
                                type="button"
                                @click="togglePasswordVisibility"
                                class="focus:outline-none"
                            >
                                <i
                                    :class="showPassword ? 'fa-regular fa-eye-slash' : 'fa-regular fa-eye'"
                                ></i>
                            </button>
                        </div>
                    </div>
                    <div class="flex justify-end items-center mb-6">
                        <a
                            href="#"
                            class="text-blue-500 text-sm hover:text-blue-700"
                            @click.prevent="openForgotPasswordModal"
                        >
                            ¿Olvidaste tu contraseña?
                        </a>
                    </div>
                    <div class="mt-5 w-full">
                        <button
                            type="submit"
                            class="ui-button-blue rounded w-full mb-3 !max-h-full"
                            :disabled="isLoadingLogin"
                        >
                          <span v-if="isLoadingLogin">
                            <LoadingSpinner/> Iniciando sesión...
                          </span>
                                        <span v-else>
                            Iniciar sesión
                          </span>
                        </button>
                    </div>
                </form>
                <div class="flex items-center justify-center mb-6">
                    <hr class="w-1/4 border-gray-300"/>
                    <span class="px-2 text-[rgb(225,228,231,0.73)] whitespace-nowrap">
                        Inicia sesión con:
                      </span>
                    <hr class="w-1/4 border-gray-300"/>
                </div>

                <SocialAuthButtons/>

                <div class="text-center mt-4">
                    <p class="text-sm text-gray-500 dark:text-gray-300">
                        ¿No tienes una cuenta?
                        <a
                            href="#"
                            class="text-blue-500 hover:text-blue-700"
                            @click.prevent="registerToggle"
                        >
                            <strong>Regístrate</strong>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div
        id="forgotPasswordModal"
        v-if="showForgotPasswordModal"
        class="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
        @click="handleClickOutsideForgotPassword"
    >
        <div
            id="forgotPasswordModalContent"
            class="relative w-full max-w-md bg-base rounded-lg shadow-lg mx-auto md:mt-20"
            @click.stop
        >
            <div class="relative p-5">
                <button
                    @click="closeForgotPasswordModal"
                    class="absolute top-2 right-2 text-white text-2xl"
                >
                    <i class="fa-solid fa-xmark"></i>
                </button>
                <div class="mb-6 text-center">
                    <h5 class="mb-3 font-bold text-xl">Recuperar contraseña</h5>
                </div>
                <form @submit.prevent="sendPasswordRecovery" method="post" action="">
                    <div class="relative mb-6">
                        <div
                            class="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none"
                        >
                            <i class="fa-regular fa-envelope text-success-emphasis"></i>
                        </div>
                        <input
                            required
                            type="email"
                            v-model="forgotPasswordForm.email"
                            name="email"
                            class="input-group"
                            placeholder="Ingrese su correo electrónico"
                        />
                    </div>
                    <div class="mt-5 w-full">
                        <button type="submit" class="ui-button-blue rounded w-full mb-3">
                            Enviar correo de recuperación
                        </button>
                    </div>
                </form>
                <p
                    class="text-blue-500 cursor-pointer text-center mt-4 hover:text-blue-700"
                    @click="closeForgotPasswordModal"
                >
                    ¿Ya tienes una cuenta? Iniciar sesión
                </p>
            </div>
        </div>
    </div>
</template>


<style scoped>
.ui-button-blue {

}
body.modal-open {
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.3);
}
</style>
