<script setup>
const props = defineProps({
    game: Object
});
const emit = defineEmits(['toggleFavorite', 'toggleLike', 'toggleFullscreen', 'toggleModeMovie']);

const toggleFavorite = () => {
    emit('toggleFavorite');
};

const toggleLike = () => {
    emit('toggleLike');
};

const toggleFullscreen = () => {
    emit('toggleFullscreen');
};

const toggleModeMovie = () => {
    emit('toggleModeMovie');
};
</script>

<template>
    <div class="flex justify-between items-center w-full px-3 py-2 bg-gray-300/20 dark:bg-gray-800 game-footer">
        <div class="flex items-center gap-3 text-gray-500">
            <button @click.prevent="toggleFavorite" :class="{ 'text-yellow-400': game.hasFavorite }"><i class="fa-solid fa-star mr-2"></i> {{ game.totalFavorites }}</button>
            <button @click.prevent="toggleLike" :class="{ 'text-red-500': game.hasLike }"><i class="fa-solid fa-heart mr-2"></i> {{ game.totalLikes }}</button>
            <button><i class="fa-regular fa-eyes mr-2"></i> {{ game.views }}</button>
        </div>
        <div class="text-gray-500 flex items-center gap-3">
            <button @click.prevent="toggleModeMovie"><i class="fa-solid fa-window-restore"></i></button>
            <button @click.prevent="toggleFullscreen"><i class="fa-sharp fa-solid fa-expand"></i></button>
        </div>
    </div>
</template>
