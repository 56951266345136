<script setup>
import {ref, onMounted, computed} from 'vue';
import {useAuthStore} from '@/Stores/Auth.js';
import DepositWidget from '@/Components/Widgets/DepositWidget.vue';
import {initFlowbite} from 'flowbite';
import {Modal} from 'flowbite';
import {useBannerStore} from "@/Stores/BannerStore.js";

const props = defineProps(['showMobile', 'isFull']);
const isLoading = ref(false);
const modalDeposit = ref(null);

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuth);

const bannerStore = useBannerStore();
const banner = computed(() => bannerStore.depositBanner);

onMounted(() => {
    initFlowbite();
    modalDeposit.value = new Modal(document.getElementById('modalElDeposit'), {
        placement: 'center',
        backdrop: 'dynamic',
        backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
        closable: true,
        onHide: () => {
            paymentType.value = null;
        },
        onShow: () => {
        },
        onToggle: () => {
        },
    });
});

const toggleModalDeposit = () => {
    modalDeposit.value.toggle();
};
</script>

<template>
    <button @click.prevent="toggleModalDeposit" type="button"
            :class="[showMobile === false ? 'hidden md:block' : '', isFull ? 'w-full' : '']"
            class="ui-button-blue rounded shadow-[0px_0px_8px_var(--ci-primary-color)] !text-center">
        <span>
            {{ $t('Deposit') }}
        </span>
    </button>
    <div
        id="modalElDeposit"
        tabindex="-1"
        aria-hidden="true"
        class="fixed inset-0 z-[101] flex items-center justify-center hidden overflow-y-auto bg-black bg-opacity-50"
        @click="toggleModalDeposit"
    >
        <div id="outside" class="w-full px-4 py-6 flex items-center justify-center">
            <div
                class="w-full max-w-lg bg-gray-900 rounded-lg shadow-xl max-h-[90vh] overflow-y-auto"
                @click.stop
            >
                <div class="relative">
                    <img
                        :src="`/storage` + banner.image"
                        alt="Banner"
                        class="w-full h-32 sm:h-40 object-cover rounded-t-lg"
                    >
                    <button
                        @click="toggleModalDeposit"
                        class="absolute text-white text-2xl"
                        style="top: calc(0.5rem + env(safe-area-inset-top)); right: calc(0.5rem + env(safe-area-inset-right));"
                    >
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div class="p-5 max-h-[calc(100vh-4rem)] overflow-y-auto">
                    <div class="mb-3 text-center">
                        <h1 class="font-bold text-xl">{{ $t('Deposit') }}</h1>
                        <p><small>{{ $t('Choose your payment method') }}</small></p>
                    </div>
                    <DepositWidget/>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.ui-button-blue {
    color: white;
    padding: 0.02rem 1rem;
    border: none;
    cursor: pointer;
    line-height: 2rem;
    transition: background-color 0.3s ease;
}
</style>
