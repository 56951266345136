<script setup>
import { ref, computed, onMounted } from 'vue';
import { useAuthStore } from '@/Stores/Auth.js';
import LandingLayout from '@/Layouts/LandingLayout.vue';
import LoadingComponent from '@/Components/UI/LoadingComponent.vue';
import LandingModal from '@/Components/LandingSpin/LandingModal.vue';

const isLoading = ref(true);
const showModal = ref(false);
const ckCarouselOriginals = ref(null);

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuth);

const CloseModal = () => {
    showModal.value = false;
    location.href = '/';
};

const HandleLoaded = () => {
    showModal.value = true;
    isLoading.value = false;
};

onMounted(() => {
    if (isAuthenticated.value) {
        location.href = '/';
    }
});
</script>

<template>
    <LandingLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>Carregando...</span>
            </div>
        </LoadingComponent>

        <div v-if="!isAuthenticated" class="container mx-auto p-4 mt-16">
            <div class="grid mb-4 mt-1">
                <div class="relative" style="height: 100vh;">
                    <LandingModal :showModal="showModal" @close="CloseModal" @loaded="HandleLoaded"/>
                </div>
            </div>
        </div>
    </LandingLayout>
</template>

<style>
html, document, body {
    user-select: none;
}
</style>
