import { initializeApp } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-app.js';
import { getDatabase } from 'https://www.gstatic.com/firebasejs/10.5.2/firebase-database.js';

export function useFirebase() {
    const firebaseConfig = {
        apiKey: "AIzaSyDuZxERSC12JRHv_CxfamTi8yhzXDqQ-zQ",
        authDomain: "magnata-f9cda.firebaseapp.com",
        databaseURL: "https://magnata-f9cda-default-rtdb.firebaseio.com",
        projectId: "magnata-f9cda",
        storageBucket: "magnata-f9cda.appspot.com",
        messagingSenderId: "644210333241",
        appId: "1:644210333241:web:53c3b7c5abf6ee30ad5fe8",
        measurementId: "G-RRPRXPQ3K7"
    };

    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);

    return { database };
}
