<script setup>
import {onMounted, computed} from 'vue';
import {RouterLink} from "vue-router";
import {useShortcutStore} from "@/Stores/ShortcutStore";

const shortcuts = computed(() => shortcutStore.shortcuts);
const shortcutStore = useShortcutStore();

onMounted(async () => {
    await shortcutStore.fetchShortcuts();
});
</script>

<template>
    <ul class="flex items-start justify-between gap-6 overflow-x-auto w-full max-w-2xl mx-auto my-4 relative z-5">
        <li class="cursor-pointer hover:opacity-80" v-for="shortcut in shortcuts" :key="shortcut.id">
            <RouterLink :to="shortcut.url"
                        active-class="active-shortcut"
                        class="flex flex-col items-center justify-center">
                <div
                    class="mb-2 flex h-[60px] w-[60px] items-center justify-center rounded-full"
                    :style="{background: shortcut.background_color}">
                    <img :src="`/storage/`+ shortcut.icon" :alt="shortcut.text" width="26"/>
                </div>
                <span
                    class="flex flex-col items-center justify-center text-center text-xs font-bold lg:text-sm"
                    :style="{color: shortcut.text_color}"
                >
                <span>{{ shortcut.text }}</span>
                <span v-if="shortcut.subtext" class="text-[10px] font-normal lg:text-xs">
                    {{ shortcut.subtext }}
                </span>
             </span>
            </RouterLink>
        </li>
    </ul>
</template>
