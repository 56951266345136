<script setup>
import { onMounted } from "vue";
import { initFlowbite } from 'flowbite';
import ReturnTop from "@/Components/UI/ReturnTop.vue";
import NavTopComponent from "@/Components/Nav/NavTopComponent.vue";
import SideBarComponent from "@/Components/Nav/SideBarComponent.vue";
import FooterComponent from "@/Components/UI/FooterComponent.vue";
import BottomNavComponent from "@/Components/Nav/BottomNavComponent.vue";
import CookiesComponent from "@/Components/UI/CookiesComponent.vue";
import MissionModal from "@/Components/Missions/MissionModal.vue";
import {sidebarStore} from "@/Stores/SideBarStore.js";

const sidebarMenuStore = sidebarStore();

onMounted(() => {
    initFlowbite();
});
</script>

<template>
    <ReturnTop />
    <NavTopComponent :simple="false" />
    <SideBarComponent v-once />
    <div :class="{
            'mt-[75px]': true,
            'sm:ml-64': sidebarStore().sidebarStatus,
            'sm:ml-[5rem]': !sidebarStore().sidebarStatus,
         }">
        <div class="relative">
            <slot></slot>
            <FooterComponent v-once />
            <BottomNavComponent v-once />
        </div>
    </div>
    <CookiesComponent v-once />
    <MissionModal />
</template>
