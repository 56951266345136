<script setup>
import AuthLayout from '@/Layouts/AuthLayout.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const handleClick = () => {
    router.push('/');
};
</script>

<template>
    <AuthLayout>
        <div class="my-auto mt-36">
            <div class="px-4 py-5">
                <div class="min-h-[calc(100vh-565px)] text-center flex flex-col items-center justify-center">
                    <img :src="`/assets/images/success.png`" alt="" class="w-36">
                    <div class="mt-5 mb-5 text-center">
                        <h1 class="text-2xl">{{ $t('Payment made successfully') }}</h1>
                    </div>
                    <button @click="handleClick" class="ui-button-blue rounded">{{ $t('Click here to return') }}</button>
                </div>
            </div>
        </div>
    </AuthLayout>
</template>

<style scoped>
</style>
