<script setup>
import {RouterLink} from "vue-router";
import {useAuthStore} from "@/Stores/Auth.js";
import {computed} from "vue";

const authStore = useAuthStore();
const userData = computed(() => authStore.user);

const emit = defineEmits(['profile-toggle', 'logout-account']);

const profileToggle = () => {
    emit('profile-toggle');
};

const logoutAccount = () => {
    emit('logout-account');
};
</script>

<template>
    <div class="flex items-center">
        <div class="flex items-center mx-auto w-full">
            <div>
                <button type="button"
                        class="profile-button flex items-center justify-center bg-gray-800 text-sm focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                        aria-expanded="false" data-dropdown-toggle="dropdown-user">
                    <span class="sr-only">Abrir menu do usuário</span>
                    <img class="h-6 w-6 rounded-full"
                         :src="userData?.avatar ? '/storage/'+userData.avatar : `/assets/images/profile.jpg`"
                         alt="">
                </button>
            </div>
            <div
                class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                id="dropdown-user">
                <div class="px-4 py-3" role="none">
                    <p class="text-sm text-gray-900 dark:text-white" role="none">
                        {{ userData?.name }}
                    </p>
                    <p class="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
                        {{ userData?.email }}
                    </p>
                </div>
                <ul class="py-1" role="none">
                    <li>
                        <RouterLink :to="{ name: 'home' }" active-class="link-active"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                <span class="w-8 h-8 mr-3">
                                    <i class="fa-duotone fa-house"></i>
                                </span>
                            {{ $t('Dashboard') }}
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'profileAffiliate' }" active-class="profile-menu-active"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                <span class="w-8 h-8 mr-3">
                                    <i class="fa-duotone fa-people-group"></i>
                                </span>
                            {{ $t('Affiliate') }}
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'profileDeposit' }" active-class="profile-menu-active"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                <span class="w-8 h-8 mr-3">
                                    <i class="fa-regular fa-money-bill-trend-up"></i>
                                </span>
                            {{ $t('Deposit') }}
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'profileWithdraw' }" active-class="profile-menu-active"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                <span class="w-8 h-8 mr-3">
                                    <i class="fa-light fa-money-bill-transfer"></i>
                                </span>
                            {{ $t('Withdraw') }}
                        </RouterLink>
                    </li>
                    <li>
                        <RouterLink :to="{ name: 'profileWallet' }" active-class="profile-menu-active"
                                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
                                <span class="w-8 h-8 mr-3">
                                  <i class="fa-duotone fa-wallet"></i>
                                </span>
                            {{ $t('My Wallet') }}
                        </RouterLink>
                    </li>
                    <li>
                        <a href="#" @click.prevent="profileToggle"
                           class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                           role="menuitem">
                                <span class="w-8 h-8 mr-3">
                                   <i class="fa-regular fa-id-card-clip"></i>
                                </span>
                            {{ $t('My Profile') }}
                        </a>
                    </li>
                    <li>
                        <a @click.prevent="logoutAccount" href="#"
                           class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                           role="menuitem">
                                 <span class="w-8 h-8 mr-3">
                                   <i class="fa-duotone fa-right-from-bracket"></i>
                                </span>
                            {{ $t('Sign out') }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
