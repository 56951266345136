<script setup>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/Stores/Auth.js';
import HttpApi from '@/Services/HttpApi.js';
import GameLayout from '@/Layouts/GameLayout.vue';
import LoadingComponent from '@/Components/UI/LoadingComponent.vue';
import GameHeader from '@/Components/Cassino/GameHeader.vue';
import GameScreen from '@/Components/Cassino/GameScreen.vue';
import GameActions from '@/Components/Cassino/GameActions.vue';
import GameTabs from '@/Components/Cassino/GameTabs.vue';

const route = useRoute();
const router = useRouter();
const authStore = useAuthStore();

const isLoading = ref(true);
const game = ref(null);
const gameUrl = ref(null);
const showButton = ref(false);
const fullscreen = ref(false);
const modeMovie = ref(false);
const undermaintenance = ref(false);

const getGame = async () => {
    try {
        const response = await HttpApi.get(`games/single/${route.params.id}`);
        if (response.data?.action === 'deposit') {
            router.push({ name: 'profileDeposit' });
        } else {
            game.value = response.data.game;
            gameUrl.value = response.data.gameUrl;
            isLoading.value = false;
        }
    } catch (error) {
        isLoading.value = false;
        undermaintenance.value = true;
    }
};

const toggleFavorite = async () => {
    await HttpApi.post(`games/favorite/${game.value.id}`);
    await getGame();
};

const toggleLike = async () => {
    await HttpApi.post(`games/like/${game.value.id}`);
    await getGame();
};

const toggleFullscreen = () => {
    fullscreen.value = !fullscreen.value;
};

const toggleModeMovie = () => {
    modeMovie.value = !modeMovie.value;
};

onMounted(async () => {
    if (authStore.isAuth) {
        await getGame();
    } else {
        router.push({ name: 'login', params: { action: 'openlogin' } });
    }
});
</script>

<template>
    <GameLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>{{ $t('Loading game information') }}</span>
            </div>
        </LoadingComponent>

        <div v-if="!isLoading && game" :class="{ 'w-full': modeMovie, 'lg:w-2/3': !modeMovie }" class="mx-auto px-2 lg:px-4 py-2 lg:py-6 relative">
            <GameHeader :game="game" />
            <GameScreen :game="game" :gameUrl="gameUrl" :showButton="showButton" />
            <GameActions :game="game" @toggleFavorite="toggleFavorite" @toggleLike="toggleLike" @toggleFullscreen="toggleFullscreen" @toggleModeMovie="toggleModeMovie" />
            <GameTabs :game="game">
                <template #default>
                    <!-- Conteúdo da aba padrão -->
                </template>
                <template #description>
                    <div v-html="game.description"></div>
                </template>
                <template #reviews>
                    <!-- Conteúdo da aba de reviews -->
                </template>
            </GameTabs>
        </div>

        <div v-if="undermaintenance" class="flex flex-col items-center justify-center text-center py-24">
            <h1 class="text-2xl mb-4">JOGO EM MANUTENÇÃO</h1>
            <img :src="`/assets/images/work-in-progress.gif`" alt="" width="400">
        </div>
    </GameLayout>
</template>
