<script setup>
import { ref, computed, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/Stores/Auth.js';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import LoadingComponent from '@/Components/UI/LoadingComponent.vue';
import RegisterForm from '@/Components/Auth/RegisterForm.vue';

const isLoading = ref(false);
const router = useRouter();
const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuth);

const routeParams = reactive({
    code: null,
});

onMounted(() => {
    if (isAuthenticated.value) {
        router.push({ name: 'home' });
    }

    const params = new URLSearchParams(window.location.search);
    if (params.has('code')) {
        routeParams.code = params.get('code');
    }
});
</script>

<template>
    <AuthLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>{{ $t('Loading') }}</span>
            </div>
        </LoadingComponent>
        <div v-if="!isLoading" class="my-auto">
            <div class="px-4 py-5">
                <div class="min-h-[calc(100vh-565px)] text-center flex flex-col items-center justify-center">
                    <div class="w-full bg-white rounded-lg shadow-lg md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-700 dark:border-gray-700">
                        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 class="mb-8 text-3xl text-center">{{ $t('Register') }}</h1>
                            <div class="mt-4 px-4">
                                <RegisterForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AuthLayout>
</template>
