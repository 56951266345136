<script setup>
import {computed, onMounted, ref} from "vue";
import {Modal} from 'flowbite';
import {useRouter} from 'vue-router';
import NavMenu from '@/Components/Nav/NavMenu.vue';
import SearchMenu from '@/Components/Nav/SearchMenu.vue';
import LoginModal from '@/Components/AuthModals/LoginModal.vue';
import RegisterModal from '@/Components/AuthModals/RegisterModal.vue';
import ProfileModal from '@/Components/AuthModals/ProfileModal.vue';
import {searchGameStore} from "@/Stores/SearchGameStore.js";
import {sidebarStore} from "@/Stores/SideBarStore.js";
import HttpApi from '@/Services/HttpApi.js';
import TextTopBanner from "@/Components/UI/TextTopBanner.vue";
import {useAuthStore} from "@/Stores/Auth.js";
import {useSettingStore} from "@/Stores/SettingStore.js";

const modalAuth = ref(null);
const modalRegister = ref(null);
const modalProfile = ref(null);
const searchGameDataStore = ref(null);
const sidebarMenuStore = sidebarStore();

const props = defineProps(['simple']);

const authStore = useAuthStore();
const router = useRouter();

const loginToggle = () => {
    modalAuth.value.toggle();
};

const registerToggle = () => {
    modalRegister.value.toggle();
};

const profileToggle = () => {
    modalProfile.value.toggle();
};

const logoutAccount = async () => {
    try {
        await HttpApi.post('auth/logout', {});
        authStore.logout();
        router.push({name: 'home'});
        _toast.success('You have been successfully disconnected');
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            _toast.error(`${value}`);
        });
    }
};

function toggleSearch() {
    searchGameDataStore.setSearchGameToogle();
}

function toggleMenu() {
    sidebarMenuStore.setSidebarToogle();
}

onMounted(() => {
    searchGameDataStore.value = searchGameStore;

    modalProfile.value = new Modal(document.getElementById('modalProfileEl'), {
        placement: 'center',
        backdrop: 'dynamic',
        backdropClasses: 'bg-gray-700 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
        closable: false,
    });

    if (!authStore.isAuth) {
        modalAuth.value = new Modal(document.getElementById('modalElAuth'), {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'bg-gray-700 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
            closable: false,
        });

        modalRegister.value = new Modal(document.getElementById('modalElRegister'), {
            placement: 'center',
            backdrop: 'dynamic',
            backdropClasses: 'bg-gray-700 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40',
            closable: false,
        });
    }
});
</script>

<template>
    <nav class="fixed navbar top-0 z-[100] w-full navtop-color border-none custom-box-shadow">
        <TextTopBanner/>

        <NavMenu :simple="props.simple"
                 @toggle-menu="toggleMenu"
                 @login-toggle="loginToggle"
                 @register-toggle="registerToggle"
                 @profile-toggle="profileToggle"
                 @logout-account="logoutAccount"
        />
        <SearchMenu/>
    </nav>

    <LoginModal
        v-if="!authStore.isAuth"
        @register-toggle="registerToggle"
        @login-toggle="loginToggle"
        />

    <RegisterModal
        v-if="!authStore.isAuth"
        @register-toggle="registerToggle"
        @login-toggle="loginToggle"
    />
    <ProfileModal @profile-toggle="profileToggle"/>
</template>
