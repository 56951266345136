<script setup>
import { ref } from 'vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import Shortcuts from '@/Components/Home/Shortcuts.vue';
import RecommendGames from "@/Components/Home/RecommendGames.vue";
import SearchGames from "@/Components/Home/SearchGames.vue";

const props = defineProps({
    banners: Array,
    bannersHome: Array
});

const settings = ref({
    itemsToShow: 1,
    snapAlign: 'center',
    autoplay: 6000,
    wrapAround: true
});

const breakpoints = ref({
    700: {
        itemsToShow: 1,
        snapAlign: 'center',
    },
    1024: {
        itemsToShow: 1,
        snapAlign: 'center',
    },
});
</script>

<template>
    <div class="carousel-banners">
        <div class="max-w-[60rem] mx-auto p-2 relative">
            <div class="mt-10 mb-5 relative z-5 px-2 md:px-0">
                <Carousel v-bind="settings" :breakpoints="breakpoints">
                    <Slide v-for="(banner, index) in banners" :key="index">
                        <div class="carousel__item rounded w-full">
                            <a :href="banner.link" class="w-full h-full bg-blue-800 rounded">
                                <img :src="`/storage/`+banner.image" alt="" class="h-full w-full rounded">
                            </a>
                        </div>
                    </Slide>
                    <template #addons>
                        <navigation>
                            <template #next>
                                <i class="fa-solid fa-chevron-right text-white"></i>
                            </template>
                            <template #prev>
                                <i class="fa-solid fa-chevron-left text-white"></i>
                            </template>
                        </navigation>
                        <Pagination/>
                    </template>
                </Carousel>
            </div>

            <SearchGames/>

            <Shortcuts/>

            <RecommendGames :banners="bannersHome"/>
        </div>
    </div>
</template>
