<script setup>
import BaseLayout from "@/Layouts/BaseLayout.vue";
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import HttpApi from "@/Services/HttpApi.js";
import SearchForm from "@/Components/Cassino/SearchForm.vue";
import GameList from "@/Components/Cassino/GameList.vue";
import LoadingComponent from "@/Components/UI/LoadingComponent.vue";
import HeaderComponent from "@/Components/UI/HeaderComponent.vue";
import EmptyDataMessage from "@/Components/Cassino/EmptyDataMessage.vue";

const route = useRoute();
const isLoading = ref(false);
const games = ref(null);
const searchTerm = ref('');

const searchGames = async () => {
    if (searchTerm.value.length > 2) {
        await getGameData(1, false);
    } else {
        await getGameData(1, false);
    }
};

const getGameData = async (page = 1, loading = true) => {
    isLoading.value = loading;
    const provider = route.params.provider;
    const category = route.params.category;

    try {
        const response = await HttpApi.get(`/casinos/games?page=${page}&searchTerm=${searchTerm.value}&category=${category}&provider=${provider}`);
        games.value = response.data.games;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

watch(() => route.params.provider, () => getGameData(1, true));
watch(() => route.params.category, () => getGameData(1, true));

onMounted(() => {
    getGameData(1, false);
});
</script>

<template>
    <BaseLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>Carregando os jogos</span>
            </div>
        </LoadingComponent>

        <div v-if="!isLoading" class="md:w-4/6 2xl:w-4/6 mx-auto">
            <div class="px-4 py-5">
                <HeaderComponent>
                    <template #header>
                        {{ $t('List of') }} <span class=" bg-blue-100 text-blue-800 text-2xl font-semibold me-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ms-2">{{ $t('Games') }}</span>
                    </template>
                    <p class="text-2xl flex items-center justify-center">{{ $t('Total') }} <strong>({{ games?.total ?? 0 }})</strong></p>
                </HeaderComponent>

                <SearchForm :searchTerm="searchTerm" @input="searchGames" />

                <GameList v-if="games && games?.total > 0" :games="games.data" />

                <EmptyDataMessage v-else />
            </div>
        </div>
    </BaseLayout>
</template>
