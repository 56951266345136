<script setup>
import { onMounted } from "vue";
import { initFlowbite } from 'flowbite';
import NavTopComponent from "@/Components/Nav/NavTopComponent.vue";
import SideBarComponent from "@/Components/Nav/SideBarComponent.vue";
import FooterComponent from "@/Components/UI/FooterComponent.vue";
import BottomNavComponent from "@/Components/Nav/BottomNavComponent.vue";
import CookiesComponent from "@/Components/UI/CookiesComponent.vue";

onMounted(() => {
    initFlowbite();
});
</script>

<template>
    <NavTopComponent :simple="false" />
    <SideBarComponent v-once />
    <div class="ml-0 md:ml-64 mt-0 md:mt-16">
        <slot></slot>
    </div>
    <FooterComponent v-once />
    <BottomNavComponent v-once />
    <CookiesComponent v-once />
</template>
