<script setup>
import { ref } from 'vue';
import { useToast } from 'vue-toastification';
import HttpApi from '@/Services/HttpApi.js';

const props = defineProps(['setting', 'wallet', 'paymentType']);
const deposit = ref({ amount: '', cpf: '', gateway: '' });
const showCouponInput = ref(false);
const couponCode = ref('');
const selectedAmount = ref(0);

const amountOptions = ref([
    { amount: 25.00, percentage: 100 },
    { amount: 50.00, percentage: 150 },
    { amount: 200.00, percentage: 200 },
    { amount: 500.00, percentage: 250 },
    { amount: 1000.00, percentage: 300 },
    { amount: 2000.00, percentage: 350 }
]);

const setAmount = (amount) => {
    deposit.value.amount = amount;
    selectedAmount.value = amount;
};

const submitQRCode = () => {
    const _toast = useToast();
    if (deposit.value.amount === '' || deposit.value.amount === undefined) {
        _toast.error('Você precisa digitar um valor');
        return;
    }

    if (deposit.value.cpf === '' || deposit.value.cpf === undefined) {
        _toast.error('Você precisa digitar seu CPF ou CNPJ');
        return;
    }

    if (parseFloat(deposit.value.amount) < parseFloat(props.setting.min_deposit)) {
        _toast.error('O valor mínimo de depósito é de ' + props.setting.min_deposit);
        return;
    }

    if (parseFloat(deposit.value.amount) > parseFloat(props.setting.max_deposit)) {
        _toast.error('O valor máximo de depósito é de ' + props.setting.max_deposit);
        return;
    }

    deposit.value.gateway = 'suitpay';

    HttpApi.post('wallet/deposit/payment', deposit.value).then(response => {
        // Handle success
    }).catch(error => {
        // Handle error
    });
};
</script>

<template>
    <div>
        <div class="mt-2 p-1 bg-gray-800 rounded-lg flex justify-between items-center">
            <input
                type="text"
                v-model="deposit.amount"
                class="w-full bg-transparent border border-gray-300 border-none rounded-md appearance-none"
                :placeholder="$t('Enter the value here')"
                :min="props.setting.min_deposit"
                :max="props.setting.max_deposit"
                required
            />
            <span class="text-white">{{ props.wallet.currency }}</span>
        </div>

        <div class="mt-1">
            <p class="text-xs ml-1 text-gray-400">
                {{ $t('Minimum deposit') }}:
                {{ state.currencyFormat(parseFloat(props.setting.min_deposit), props.wallet.currency) }}
            </p>
        </div>

        <div class="mt-4">
            <button @click="showCouponInput = !showCouponInput" class="text-gray-400 hover:text-white transition-colors text-sm">
                ¿Tienes un cupón?
            </button>

            <div v-if="showCouponInput" class="mt-2 p-1 bg-gray-800 rounded-lg">
                <input
                    type="text"
                    v-model="couponCode"
                    class="w-full bg-transparent border-none rounded-md appearance-none"
                    placeholder="Enter coupon code"
                />
            </div>
        </div>

        <div class="mt-5 item-selected">
            <div
                v-for="(value, index) in amountOptions"
                :key="index"
                @click.prevent="setAmount(value.amount)"
                class="item !m-0" style="width: 100%;"
                :class="{ 'active': selectedAmount === value.amount }"
            >
                <button type="button" class="deposit-button min-h-[50px] min-w-[100px]">{{ props.wallet.symbol }} {{ value.amount.toFixed(2) }}</button>
                <img v-if="selectedAmount === value.amount" class="img-check" :src="`/assets/images/check.webp`" alt="">
            </div>
        </div>

        <button @click.prevent="submitQRCode" class="custom-button mt-6">
            {{ $t('Deposit') }}
        </button>
    </div>
</template>

<style scoped>
.item-selected {
    display: flex;
    gap: 0.5rem;
    overflow-x: auto;
}

.custom-button {
    width: 100%;
    padding: 0.75rem 1.5rem;
    background-color: var(--ci-primary-color);
    color: #f1f0ef;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 0px 8px var(--ci-primary-color);
}

.custom-button:hover {
    background-color: var(--ci-secundary-color);
    box-shadow: 0px 0px 12px var(--ci-secundary-color);
}
</style>
