<script setup>
import { onMounted } from "vue";
import { initFlowbite } from 'flowbite';
import NavTopComponent from "@/Components/Nav/NavTopComponent.vue";
import FooterComponent from "@/Components/UI/FooterComponent.vue";
import BottomNavComponent from "@/Components/Nav/BottomNavComponent.vue";
import CookiesComponent from "@/Components/UI/CookiesComponent.vue";

onMounted(() => {
    initFlowbite();
});
</script>

<template>
    <NavTopComponent :simple="false" />
    <div class="">
        <div class="relative">
            <slot></slot>
            <FooterComponent v-once />
            <BottomNavComponent v-once />
        </div>
    </div>
    <CookiesComponent v-once />
</template>
