<script setup>
import {computed} from "vue";
import {useAuthStore} from "@/Stores/Auth.js";
import {useSettingStore} from "@/Stores/SettingStore.js";
import AuthButtons from "@/Components/Nav/NavMenu/AuthButtons.vue";
import UserMenu from "@/Components/Nav/NavMenu/UserMenu.vue";
import MakeDeposit from "@/Components/UI/MakeDeposit.vue";
import WalletBalance from "@/Components/UI/WalletBalance.vue";
import NavigationLinks from "@/Components/Nav/NavMenu/NavigationLinks.vue";
import MissionButton from "@/Components/Nav/NavMenu/MissionButton.vue";
import ApplicationLogo from "@/Components/Nav/NavMenu/ApplicationLogo.vue";
import ToggleMenuButton from "@/Components/Nav/NavMenu/ToggleMenuButton.vue";

const settingStore = useSettingStore();
const props = defineProps(['simple']);
const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuth);
const setting = computed(() => settingStore.setting);

const emit = defineEmits(['toggle-menu', 'login-toggle', 'register-toggle', 'profile-toggle', 'logout-account']);

const toggleMenu = () => {
    emit('toggle-menu');
};

const loginToggle = () => {
    emit('login-toggle');
};

const registerToggle = () => {
    emit('register-toggle');
};

const profileToggle = () => {
    emit('profile-toggle');
};

const logoutAccount = () => {
    emit('logout-account');
};
</script>

<template>
    <div class="nav-menu flex items-center gap-6">
        <NavigationLinks/>
        <div class="w-full">
            <div
                class="mx-auto flex items-center justify-between px-4 py-2 lg:px-3 lg:py-4">
                <div class="flex items-center justify-start">
                    <ToggleMenuButton @toggle-menu="toggleMenu"/>
                    <div class="flex items-center gap-3 hover:opacity-90">
                        <ApplicationLogo :setting="setting"/>
                        <MissionButton/>
                    </div>
                </div>
                <div class="hidden md:block"></div>
                <AuthButtons v-if="!isAuthenticated"
                             @login-toggle="loginToggle"
                             @register-toggle="registerToggle"/>

                <div class="flex items-center gap-2" v-else>
                    <MakeDeposit/>
                    <WalletBalance/>
                    <UserMenu @profile-toggle="profileToggle" @logout-account="logoutAccount"/>
                </div>
            </div>
        </div>
    </div>
</template>
