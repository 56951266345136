<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

const props = defineProps(['initialMinutes', 'initialSeconds']);
const minutes = ref(props.initialMinutes);
const seconds = ref(props.initialSeconds);
let timer = null;

const startTimer = () => {
    timer = setInterval(() => {
        if (seconds.value > 0) {
            seconds.value--;
        } else {
            if (minutes.value === 0 && seconds.value === 0) {
                clearInterval(timer);
                return;
            }
            minutes.value--;
            seconds.value = 59;
        }
    }, 1000);
};

const formatTime = (value) => (value < 10 ? `0${value}` : value);

onMounted(startTimer);
onBeforeUnmount(() => clearInterval(timer));
</script>

<template>
    <div class="flex space-x-1 text-lg">
        <span class="timer-number">{{ formatTime(minutes) }}</span>:
        <span class="timer-number">{{ formatTime(seconds) }}</span>
    </div>
</template>

<style scoped>
.timer-number {
    background-color: var(--ci-primary-color);
    border-radius: .25rem;
    display: grid;
    font-size: .75rem;
    font-weight: 700;
    height: 25px;
    line-height: 1rem;
    place-items: center;
    width: 25px;
    color: white;
}
</style>
