import {defineStore} from 'pinia'
import HttpApi from "@/Services/HttpApi.js";

export const useBannerStore = defineStore('BannerStore', {
    state() {
        return {
            banners: [],
            bannersHome: [],
            depositBanner: {},
            loginBanner: {},
            registerBanner: {},
        }
    },

    actions: {
        async fetchBanners() {
            try {
                const response = await HttpApi.get('settings/banners');
                const allBanners = response.data.banners;
                this.banners = allBanners.filter(banner => banner.type === 'carousel');
                this.bannersHome = allBanners.filter(banner => banner.type === 'home');
                this.depositBanner = allBanners.find(banner => banner.type === 'deposit');
                this.registerBanner = allBanners.find(banner => banner.type === 'register');
                this.loginBanner = allBanners.find(banner => banner.type === 'login');
            } catch (error) {}
        }
    },
});
