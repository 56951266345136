<script setup>
import { ref } from 'vue';
import QRCodeVue3 from 'qrcode-vue3';
import { useToast } from 'vue-toastification';

const props = defineProps(['qrcodecopypast', 'amount', 'currency']);
const copyQRCode = () => {
    const _toast = useToast();
    var inputElement = document.getElementById("pixcopiaecola");
    inputElement.select();
    inputElement.setSelectionRange(0, 99999);  // Para dispositivos móveis

    // Copia o conteúdo para a área de transferência
    document.execCommand("copy");
    _toast.success('Pix Copiado com sucesso');
};
</script>

<template>
    <div class="flex flex-col">
        <div class="w-full p-4 mb-3 bg-white rounded dark:bg-gray-700">
            <div class="flex justify-between">
                <h2 class="text-lg">Falta pouco! Escaneie o código QR pelo seu app de pagamentos ou Internet Banking</h2>
                <div class="text-4xl">
                    <i class="fa-regular fa-circle-dollar"></i>
                </div>
            </div>
        </div>

        <div class="w-full p-4">
            <div>
                <p class="font-bold">Valor do Pix a pagar: {{ state.currencyFormat(parseFloat(props.amount), props.currency) }}</p>
            </div>
            <div class="flex items-center justify-center p-3">
                <QRCodeVue3 :value="props.qrcodecopypast" />
            </div>
            <div>
                <p class="font-bold">Código válido por 23 horas.</p>
            </div>
            <div class="mt-4">
                <p class="mb-3">Se preferir, você pode pagá-lo copiando e colando o código abaixo:</p>
                <input id="pixcopiaecola" type="text" class="input" v-model="props.qrcodecopypast">
            </div>

            <div class="flex items-center justify-center w-full mt-5">
                <button @click.prevent="copyQRCode" type="button" class="w-full ui-button-blue">
                    <span class="text-sm font-semibold uppercase">{{ $t('Copy code') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.ui-button-blue {
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
</style>
