<script setup>
import FooterColumn from '@/Components/Footer/FooterColumn.vue';
import LanguageSelector from '@/Components/Footer/LanguageSelector.vue';
import {useFooterStore} from "@/Stores/Footer.js";
import {ref} from 'vue';

const year = new Date().getFullYear();

const columns = useFooterStore().columns;
const languages = useFooterStore().languages;
const logoTM = useFooterStore().logoTM;
const footerText = useFooterStore().footerText;
const selectedLanguage = useFooterStore().selectedLanguage;

const isExpanded = ref(false);

function toggleExpand() {
    isExpanded.value = !isExpanded.value;
}
</script>

<template>
    <div class="footer pb-32 md:pb-5 mt-8 footer-color p-4 md:p-8 !border-t !border-solid !border-gray-400">
        <div class="lg:w-5/6 2xl:w-4/6 mx-auto p-2 grid gap-5 grid-cols-1">
            <div
                class=" grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 gap-8 justify-between items-start">
                <div class="flex flex-col items-start text-gray-600 dark:text-gray-400">

                    <img :src="`/assets/images/icons/flamix.png`" alt="flamix" width="300" class="-ml-4 mr-4"/>
                    <div class="flex mt-6">
                        <a href="https://t.me/flamixcasino" target="_blank" class="mr-2">
                            <img :src="`/assets/images/icons/telegram.svg`" alt="Telegram" width="25"
                                 class="filter-color"/>
                        </a>
                        <img :src="`/assets/images/icons/instagram.svg`" alt="Instagram" width="25"
                             class="mr-2 filter-color"/>
                    </div>
                </div>
                <FooterColumn v-for="column in columns" :key="column.id" :column="column"/>
            </div>
            <div class="items-center justify-center grid gap-5 grid-cols-1">
                <div
                    class="relative py-6 after:absolute after:left-0 after:top-full after:h-px after:w-full after:bg-white after:opacity-20 after:content-['']">
                    <div
                        :class="`text-footer-texts pb-2 text-center text-[12px] text-gray-600 dark:text-gray-400 ${isExpanded ? '' : 'max-h-16 custom-overflow-hidden'}`">
                        <p>A Flamix Casino | Cassino Online é um site de apostas esportivas e cassino online, que
                            oferece aos seus usuários uma experiência única de apostas em diversos esportes, incluindo
                            futebol, basquete, tênis, vôlei, entre outros.</p>
                        <p>Com uma plataforma intuitiva e fácil de usar, a Flamix Casino | Cassino Online permite que
                            seus usuários apostem em jogos ao vivo, além de oferecer uma ampla variedade de opções de
                            apostas pré-jogo.</p>
                        <p>Com o objetivo de proporcionar a melhor experiência de apostas aos seus usuários, oferecemos
                            uma ampla gama de recursos e ferramentas, incluindo estatísticas detalhadas dos jogos,
                            histórico de resultados, análises de especialistas e muito mais.</p>
                        <p>Além disso, o site oferece diversas promoções e bônus exclusivos para seus usuários,
                            aumentando as chances de lucro e proporcionando uma experiência ainda mais emocionante.</p>
                        <p>Somos uma plataforma segura e confiável, que oferece suporte ao cliente 24 horas por dia,
                            sete dias por semana. Com uma equipe de especialistas em apostas esportivas e cassino
                            online, Donald Bet está comprometida em garantir que seus usuários tenham uma experiência
                            positiva e segura, e possam desfrutar de todas as emoções e oportunidades oferecidas pelas
                            apostas esportivas de forma responsável e consciente.</p>
                    </div>

                    <button
                        @click="toggleExpand"
                        class="pt-12 absolute bottom-[-20px] z-10 w-full bg-gradient-to-t from-[var(--background-base)] to-transparent text-[.7rem] text-gray-600 dark:text-gray-400 md:bottom-[-1px]"
                    >
                        {{ isExpanded ? 'Ver menos' : 'Ver mais' }}
                    </button>

                </div>
                <div class="text-center grid gap-5 grid-cols-1">
                    <div class="flex justify-center items-center">
                        <img :src="logoTM.src" class="" :alt="logoTM.alt" :style="{ width: logoTM.width }"/>
                    </div>
                    <p class="text-[12px]">¡Hecho con ❤️ para todo el mundo!</p>
                </div>
                <div class="grid gap-5 grid-cols-1">
                    <div class="flex items-center justify-end">
                        <p class="text-[12px] md:text-sm text-gray-600 dark:text-gray-400 mr-4">
                            {{ footerText }}
                        </p>
                        <img :src="`/assets/images/icons/bcg.png`" alt="bcg" width="170"/>
                    </div>
                    <div class="flex flex-col items-center gap-4">
                        <div class="flex gap-1">
                            <img :src="`/assets/images/icons/gt_logo.png`" alt="gt" width="70" class="mr-4"/>
                            <img :src="`/assets/images/icons/juega.png`" alt="juega" width="200"/>
                            <img :src="`/assets/images/icons/BeGambleAware.png`" alt="juega" width="200"/>
                        </div>
                        <div class="mt-2 flex items-center text-gray-600 dark:text-gray-400 text-sm flex-wrap">
                            <span class="font-bold">Suporte:</span><span class="ml-1 font-bold">suportesuporte@flamix.casino</span>

                            <span class="mx-2 font-bold">|</span>

                            <span class="font-bold">Jurídico:</span><span
                            class="ml-1 font-bold">juridico@flamix.casino</span>

                            <span class="mx-2 font-bold">|</span>

                            <span class="font-bold">Parceiros:</span><span class="ml-1 font-bold">parceiros@flamix.casino</span>
                        </div>


                        <LanguageSelector :languages="languages" :selectedLanguage="selectedLanguage"/>
                    </div>
                    <div class="flex justify-center items-center">
                        <p class="text-[12px]">© {{ year }} Rocket Gaming - Todos os direitos reservados.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
/* Estilos para o texto e botões */
.custom-overflow-hidden {
    overflow: hidden;
}

.filter-color {
    filter: invert(42%) sepia(6%) saturate(0%) hue-rotate(180deg) brightness(92%) contrast(90%);
}

.dark .filter-color {
    filter: invert(61%) sepia(7%) saturate(118%) hue-rotate(184deg) brightness(88%) contrast(85%);
}

</style>
