<script setup>
import {ref, onMounted, computed, reactive, onUnmounted} from 'vue';
import {useSettingStore} from '@/Stores/SettingStore.js';
import HttpApi from '@/Services/HttpApi.js';
import {useToast} from 'vue-toastification';
import ButtonsHighlighted from "@/Components/Sidebar/ButtonsHighlighted.vue";
import Menu from "@/Components/Sidebar/Menu.vue";
import Footer from "@/Components/Sidebar/Footer.vue";
import {useTextBannerStore} from "@/Stores/TextBannerStore.js";
import {sidebarStore} from "@/Stores/SideBarStore.js";
import {useRouter} from "vue-router";
import ButtonsHighlightedIcons from "@/Components/Sidebar/ButtonsHighlightedIcons.vue";
import MenuIcons from "@/Components/Sidebar/MenuIcons.vue";
import FooterIcons from "@/Components/Sidebar/FooterIcons.vue";

const isMobile = ref(window.innerWidth < 768);
const isLoading = ref(true);
const categories = ref([]);
const setting = ref(null);
const toast = useToast();
const textBannerStore = useTextBannerStore();
const router = useRouter();
const buttons = reactive([]);
const footerLinks = reactive([]);

const showTextBanner = computed(() => textBannerStore.showTextBanner);

const settingStore = useSettingStore();

const getCasinoCategories = async () => {
    isLoading.value = true;
    try {
        const response = await HttpApi.get('categories');
        categories.value = response.data.categories;
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            toast.error(`${value}`);
        });
    } finally {
        isLoading.value = false;
    }
};

const getSetting = () => {
    const settingData = settingStore.setting;
    if (settingData) {
        setting.value = settingData;
    }
};

const getButtonsHighlighted = async () => {
    await HttpApi.get('/layout/highlight-buttons').then(response => {
        const data = response.data;

        data.forEach(button => {
            buttons.push({
                title: button.title,
                action: () => {
                    router.push({name: button.link});
                },
                text_color: button.text_color,
                background_color: button.background_color,
                border_color: button.border_color,
                emoji: button.emoji,
                icon: button.icon,  // Adicionando a propriedade de ícone
                icon_height: button.icon_height || 17,  // Altura do ícone
                icon_width: button.icon_width || 17,    // Largura do ícone
                icon_viewbox: button.icon_viewbox || '0 0 512 512', // ViewBox padrão
                icon_svg: button.icon_svg  // SVG a ser usado
            });
        });
    });
};

const getFooterLinks = async () => {
    await HttpApi.get('/layout/sidebar-footer-links').then(response => {
        footerLinks.push(...response.data);
    });
};

const handleResize = () => {
    isMobile.value = window.innerWidth < 768;
};

onMounted(() => {
    getCasinoCategories();
    getSetting();
    getButtonsHighlighted();
    getFooterLinks();

    if (window.innerWidth < 1024) {
        sidebarStore().setSidebarStatus(false);
    }

    window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});
</script>

<template>
    <aside v-show="(isMobile && sidebarStore().sidebarStatus) || (!isMobile && sidebarStore().sidebarStatus)"
           :class="[
              showTextBanner ? 'lg:pt-[40px]' : 'lg:pt-0',
              'sm:w-64',  // Largura para telas maiores
              'w-full'    // Largura para telas menores
            ]"
           class="md:max-w-[250px] bg-red-500 fixed left-0 z-[99] h-screen transition-transform sm:translate-x-0 sidebar-color custom-side-shadow border-r-[1px] border-r-slate-50 border-opacity-10"
           aria-label="Sidebar">

        <div class="h-full pb-4 overflow-y-auto sidebar-color">
            <ButtonsHighlighted :buttons="buttons"/>
            <template v-if="!isLoading && categories.length > 0">
                <Menu :categories="categories"/>
            </template>
            <Footer :links="footerLinks"/>
        </div>
    </aside>
    <div v-if="!isMobile">
        <aside v-show="(!isMobile && !sidebarStore().sidebarStatus)"
               :class="[
              showTextBanner ? 'lg:pt-[35px]' : 'lg:pt-0',
              'sm:w-[70px]',
              'w-[50px]',
            ]"
               class="fixed left-0 z-[99] w-full-mobile h-screen transition-transform  sm:translate-x-0 sidebar-color custom-side-shadow border-r-[1px] border-r-slate-100 border-opacity-10"
               id="icon-sidebar"
               aria-label="Sidebar">
            <div class="h-full pb-4 overflow-y-auto sidebar-color">
                <ButtonsHighlightedIcons :buttons="buttons"/>
                <template v-if="!isLoading && categories.length > 0">
                    <MenuIcons :categories="categories"/>
                </template>
                <FooterIcons :links="footerLinks"/>
            </div>
        </aside>
    </div>
</template>
