<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/Stores/Auth.js';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import LoadingComponent from '@/Components/UI/LoadingComponent.vue';
import LoginForm from '@/Components/Auth/LoginForm.vue';
import SocialLogin from '@/Components/Auth/SocialLogin.vue';

const isLoading = ref(false);
const router = useRouter();
const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuth);

onMounted(() => {
    if (isAuthenticated.value) {
        router.push({ name: 'home' });
    }
});
</script>

<template>
    <AuthLayout>
        <LoadingComponent :isLoading="isLoading">
            <div class="text-center">
                <span>{{ $t('Loading') }}</span>
            </div>
        </LoadingComponent>

        <div v-if="!isLoading" class="my-auto mt-36">
            <div class="px-4 py-5">
                <div class="min-h-[calc(100vh-565px)] text-center flex flex-col items-center justify-center">
                    <div class="w-full rounded-lg shadow-lg border-none md:mt-0 sm:max-w-md xl:p-0 bg-base">
                        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 class="mb-8 text-3xl text-center">{{ $t('Login') }}</h1>
                            <div class="mt-4 px-4">
                                <LoginForm />
                                <SocialLogin />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AuthLayout>
</template>
