<script setup>
import {ref, computed, onMounted} from 'vue';
import {useAuthStore} from '@/Stores/Auth.js';
import {useSettingStore} from '@/Stores/SettingStore.js';
import Timer from '@/Components/Widgets/DepositWidget/Timer.vue';
import PaymentButton from '@/Components/Widgets/DepositWidget/PaymentButton.vue';
import DepositForm from '@/Components/Widgets/DepositWidget/DepositForm.vue';
import QRCodeDisplay from '@/Components/Widgets/DepositWidget/QRCodeDisplay.vue';
import HttpApi from '@/Services/HttpApi.js';
import {useToast} from 'vue-toastification';

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.isAuth);
const paymentType = ref(null);
const showPixQRCode = ref(false);
const qrcodecopypast = ref('');
const setting = ref(null);
const wallet = ref(null);
const isLoadingWallet = ref(false);
const currency = ref(null);
const amount = ref(null);

const setPaymentMethod = (type) => {
    paymentType.value = type;
};

const getWallet = async () => {
    const _toast = useToast();
    isLoadingWallet.value = true;

    try {
        const response = await HttpApi.get('profile/wallet');
        wallet.value = response.data.wallet;
        currency.value = response.data.wallet.currency;
    } catch (error) {
        Object.entries(JSON.parse(error.request.responseText)).forEach(([key, value]) => {
            _toast.error(`${value}`);
        });
    } finally {
        isLoadingWallet.value = false;
    }
};

const getSetting = () => {
    const settingStore = useSettingStore();
    const settingData = settingStore.setting;

    if (settingData) {
        setting.value = settingData;
        amount.value = settingData.max_deposit;

        if (paymentType.value === 'stripe' && settingData.stripe_is_enable) {
            getSession();
        }
    }
};

onMounted(async () => {
    if (isAuthenticated.value) {
        await Promise.all([getWallet(), getSetting()]);
    }
});
</script>

<template>
    <div class="p-4 bg-gray-800/50">
        <div class="flex justify-between items-center text-white">
            <div class="text-sm">
                Realize seu primeiro depósito e ganhe até 80 giros no Fortune Tiger
            </div>
            <Timer :initialMinutes="8" :initialSeconds="10"/>
        </div>
    </div>

    <div class="grid grid-cols-2 gap-3 p-4" v-if="setting">
        <PaymentButton
            v-if="setting.suitpay_is_enable"
            :paymentType="'pix'"
            :isActive="paymentType === 'pix'"
            imageSrc="/assets/images/pix.png"
            altText="PIX"
            @click="setPaymentMethod('pix')"
        />
        <PaymentButton
            :paymentType="'stripe'"
            :isActive="paymentType === 'stripe'"
            imageSrc="/assets/images/stripe.png"
            altText="Stripe"
            @click="setPaymentMethod('stripe')"
        />
    </div>

    <DepositForm v-if="!showPixQRCode && paymentType === 'pix'" :setting="setting" :wallet="wallet"
                 :paymentType="paymentType"/>

    <QRCodeDisplay v-if="showPixQRCode && wallet" :qrcodecopypast="qrcodecopypast" :amount="amount"
                   :currency="currency"/>
</template>
