import { defineStore } from 'pinia'

export const searchGameStore = defineStore('searchGames', {
    state() {
        return {
            searchGameStatus: false,
        }
    },

    actions: {
        setSearchGameToogle() {
            this.searchGameStatus = !this.searchGameStatus;
        },
    },

    getters: {
        getSearchGameStatus() {
            return this.searchGameStatus;
        },
    },

})
